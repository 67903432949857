<template>
  <v-dialog
    :value="typeof value === 'object' && value !== null"
    @input="close()"
    max-width="600"
  >
    <v-card>
      <v-card-title class="text-h5"> {{ action }} Work Center? </v-card-title>

      <v-card-text>
        Are you sure you want to {{ action.toLowerCase() }} " {{ title }} "?
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>

        <v-btn color="red darken-1" text @click="close()"> Cancel </v-btn>

        <v-btn color="primary darken-1" text @click="toggleWorkcenter()">
          {{ action }} Work Center
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { computed } from "vue-demi";

export default {
  name: "WorkcenterActivationDialog",
  props: {
    value: {
      default: null,
    },
  },
  setup(props, context) {
    const action = computed(() => {
      return props.value?.active ? "Disable" : "Enable";
    });
    const title = computed(() => {
      return props.value?.title ?? "";
    });

    function showError(errorMessage) {
      // TODO: Show error message
      console.error(errorMessage);
    }

    function close() {
      context.emit("input", null);
    }

    function toggleWorkcenter() {
      // TODO: implement error handling
      props.value.active = !props.value.active;
      props.value.save().then(() => {
        close();
      });
    }

    return {
      action,
      title,
      showError,
      close,
      toggleWorkcenter,
    };
  },
};
</script>
