<template>
  <v-container>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn color="success" @click="createWorkcenter()">New Work Center</v-btn>
    </v-card-actions>
    <v-form @submit.prevent="">
      <v-row>
        <v-col cols="12">
          <v-text-field
            v-model="options.params.title"
            label="Work Center Name"
            clearable
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="6">
          <v-select
            v-model="options.params.facility_id"
            label="Facility"
            :items="facilitiesStore.activeFacilities"
            item-text="title"
            item-value="id"
            clearable
          ></v-select>
        </v-col>
        <v-col cols="12" md="6">
          <v-select
            v-model="options.params.active"
            :items="activeFilterOptions"
            item-text="title"
            item-value="value"
            label="Status"
          ></v-select>
        </v-col>
      </v-row>
    </v-form>
    <v-data-table v-bind="defaultAttrs" :options.sync="options">
      <!-- eslint-disable-next-line vue/valid-v-slot -->
      <template v-slot:item.facility_id="{ item }">
        {{ getFacilityName(item.facility_id) }}
      </template>
      <!-- eslint-disable-next-line vue/valid-v-slot -->
      <template v-slot:item.active="{ item }">
        {{ item.active ? "Active" : "Inactive" }}
      </template>
      <!-- eslint-disable-next-line vue/valid-v-slot -->
      <template v-slot:item.actions="{ item }">
        <v-row>
          <v-col cols="12">
            <ButtonAction
              label="View"
              icon="mdi-eye"
              @click="quickView(item)"
            />
            <ButtonAction
              label="Edit"
              icon="mdi-pencil"
              @click="navigateToEdit(item.id)"
            />
            <ButtonAction
              :label="item.active ? 'Deactivate' : 'Activate'"
              icon="mdi-power-standby"
              :color="item.active ? 'green' : 'red'"
              @click="enableTogglePopup(item)"
            />
          </v-col>
        </v-row>
      </template>
    </v-data-table>
    <WorkcenterActivationDialogVue v-model="workcenterToggle" />
    <WorkcentersViewDialogVue
      v-model="quickViewItem"
      :facility="quickViewItemFacility"
    />
  </v-container>
</template>

<script>
import { mapActions } from "pinia";
import { ref, computed } from "vue-demi";
import dataTablePagination from "@/mixins/dataTablePagination";
import ButtonAction from "./ButtonAction.vue";
import WorkcenterActivationDialogVue from "./WorkcenterActivationDialog.vue";
import WorkcentersViewDialogVue from "./WorkcentersViewDialog.vue";
import { useFacilities } from "@/store/facilities.pinia.js";
import { useWorkcenters } from "@/store/workcenters.pinia.js";

export default {
  name: "WorkcentersBrowse",
  components: {
    ButtonAction,
    WorkcenterActivationDialogVue,
    WorkcentersViewDialogVue,
  },
  mixins: [dataTablePagination],
  setup() {
    const quickViewItem = ref(null);
    const facilities = ref([]);
    // Get quick view facility name
    const quickViewItemFacility = computed(() => {
      return facilities?.value?.find(
        (facility) => facility.id === quickViewItem.value?.facility_id
      );
    });
    const facilitiesStore = useFacilities();
    // Query for all facilities
    facilitiesStore.find({ query: {} }).then((res) => {
      facilities.value = res.data;
    });

    function quickView(item) {
      quickViewItem.value = item;
    }

    function getFacilityName(id) {
      return (
        this.facilities.find((facility) => facility.id === id)?.title ?? ""
      );
    }

    return {
      quickViewItemFacility,
      getFacilityName,
      quickViewItem,
      quickView,
      facilities,
      facilitiesStore,
    };
  },
  data() {
    return {
      workcenterToggle: null,
      workcenterToggleTitle: null,
      activeItemId: null,
      defaultParams: {
        active: true,
      },
      defaultOptions: {
        sortBy: ["title"],
        sortDesc: [false],
      },
      activeFilterOptions: [
        { title: "All", value: null },
        { title: "Active", value: true },
        { title: "Inactive", value: false },
      ],
      headers: [
        // { text: "ID", value: "id" },
        { text: "Facility Name", value: "facility_id" },
        { text: "Work Center Name", value: "title" },
        { text: "Status", value: "active" },
        { text: "Actions", value: "actions" },
      ],
    };
  },
  methods: {
    ...mapActions(useWorkcenters, ["getPaginated"]),
    createWorkcenter() {
      this.$router.push({ name: "WorkcentersEdit" });
    },
    setActiveItem(id) {
      this.activeItemId = id;
    },
    enableTogglePopup(item) {
      this.workcenterToggle = item;
    },
    navigateToEdit(id) {
      this.$router.push({ name: "WorkcentersEdit", params: { id } });
    },
  },
  watch: {
    workcenterToggle(newVal, oldVal) {
      if (newVal === null && !!oldVal) {
        this.refresh();
      }
    },
  },
};
</script>

<style scoped></style>
