<template>
  <v-dialog :value="value !== null" @input="close()" max-width="600">
    <v-card>
      <LoadingContainerVue :loading="loading">
        <v-card-title class="text-h5">Work Center - {{ title }}</v-card-title>

        <v-card-text>
          <v-row>
            <v-col v-for="item in rows" :key="item.label" cols="12" md="6">
              <h6 class="text-h6">{{ item.label }}</h6>
              <span class="text-body-1">{{ item.value }}</span>
            </v-col>
          </v-row>
        </v-card-text>

        <v-expansion-panels>
          <v-expansion-panel>
            <v-expansion-panel-header> Audit Log </v-expansion-panel-header>
            <v-expansion-panel-content> TBD </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn color="primary darken-1" text @click="close()"> Close </v-btn>
        </v-card-actions>
      </LoadingContainerVue>
    </v-card>
  </v-dialog>
</template>

<script>
import { ref, computed } from "vue-demi";
import LoadingContainerVue from "./LoadingContainer.vue";

export default {
  name: "WorkcentersViewDialog",
  components: {
    LoadingContainerVue,
  },
  props: {
    value: {
      default: null,
    },
    facility: {
      default: null,
    },
  },
  setup(props, context) {
    const loading = ref(true);

    const title = computed(() => {
      return props.value?.title ?? "";
    });

    const rows = computed(() => {
      return [
        {
          label: "Work Center Name",
          value: title.value,
        },
        {
          label: "Facility",
          value: props.facility?.title ?? "",
        },
        {
          label: "Active",
          value: props.value?.active ? "Yes" : "No",
        },
        {
          label: "Modified Date",
          value: props.value?.modified || "",
        },
        // {
        //   label: "Created By",
        //   value: props.value?.created_by?.username || "",
        // },
        // {
        //   label: "Modified By",
        //   value: props.value?.modified_by?.username || "",
        // },
      ];
    });

    function close() {
      context.emit("input", null);
    }

    loading.value = false;

    return {
      loading,
      title,
      rows,
      close,
    };
  },
};
</script>
